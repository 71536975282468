import React, { useState, useEffect } from "react";
import './styles.css'
import { db, auth } from "../../services/firebaseConnection"
import { useForm, Controller } from "react-hook-form";
import { ref, set, onValue, child, push, update, remove } from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { read, utils, writeFile } from 'xlsx';
import { BiArrowBack, BiInfoCircle } from "react-icons/bi";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

function Campos() {

  const [projeto, setProjeto] = useState([])
  const [projetoDados, setProjetoDados] = useState([])
  const [projetoPontos, setProjetoPontos] = useState([])
  const [projetoPontosList, setProjetoPontosList] = useState([])
  const [projetosD, setProjetosD] = useState([])
  const [cadastrar, setCadastrar] = useState("")
  const [editar, setEditar] = useState("")
  const [alert, setAlert] = useState(false)
  const [cdProjeto, setCdProjeto] = useState(false)
  const [editProjeto, setEditProjeto] = useState(false)
  const [projSelec, setProjSelec] = useState('')
  const [selecAdd, setSelecAdd] = useState(true)
  const [cdLinhaT, setCdLinhaT] = useState(false)
  const [dataXlsx, setDataXlsx] = useState([]);
  const [listCm, setListCm] = useState([]);


  const editSucess = () => toast.success('O projeto foi editado com sucesso!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const cdSucess = () => toast.success('O projeto foi cadastrado com sucesso!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const erroXls = () => toast.error('O arquivo não foi anexado!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const schema = yup.object({
    projeto: yup.string().required("Informe o nome do projeto!"),
    municipio: yup.string().required("Informe o nome do municipio e o estado abreviado!"),
    processo: yup.string().required("Informe o número do processo!"),
    tipo: yup.string().required("Informe o tipo de intervenção!"),
    /*prefixo: yup.string().required("Informe o prefixo da intervenção!"),*/
    quantidade: yup.string().required("Informe a quantidade de intervenções!"),
  })

  const schema2 = yup.object({
    projeto: yup.string().required("Informe o nome do projeto!"),
    municipio: yup.string().required("Informe o nome do municipio!"),
    processo: yup.string().required("Informe o número do processo!"),
    prefixo: yup.string().required("Informe o prefixo da intervenção!"),
    quantidade: yup.string().required("Informe a quantidade de intervenções!"),
  })

  const schema3 = yup.object({
    projeto: yup.string().required("Informe o nome do projeto!"),
    processo: yup.string().required("Informe o número do processo!"),
    municipio: yup.string().required("Informe o nome do municipio e o estado abreviado!")
  })

  const { control, handleSubmit, reset: reset2, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const { control: control2, handleSubmit: handleSubmit2, reset, formState: { errors: errors2 } } = useForm({
    resolver: yupResolver(schema2)
  });

  const { control: control3, handleSubmit: handleSubmit3, reset: reset3, formState: { errors: errors3 } } = useForm({
    resolver: yupResolver(schema3)
  });

  async function onSubmitPT(data) {
    const array = []
    const size = parseInt(data.quantidade)
    const sizeNumber = size + 1

    if (data.tipo === "nm") {
      for (let i = 1; i < sizeNumber; i++) {
        array.push("PT -" + i)
      }
    } else if (data.tipo === "pv") {
      for (let i = 1; i < sizeNumber; i++) {
        array.push("PV -" + i)
      }
    }

    /*for (let i = 1; i < sizeNumber; i++) {
      array.push(data.prefixo + "-" + i)
    }*/

    const projetoNew = { projeto: data.projeto }
    const newArrPro = [...listCm, projetoNew]

    set(ref(db, 'projetos/' + data.projeto), {
      projeto: data.projeto,
      municipio: data.municipio,
      pontos: array,
      especi: data.tipo,
      processo: data.processo
    })

    const projeto = {}
    projeto['projetos/list'] = newArrPro
    await update(ref(db), projeto);

    setSelecAdd(true)
    setCdProjeto(false)
    reset2({})
    cdSucess()

    
  };

  async function onSubmit2(data) {

    if (dataXlsx[0] === undefined) {
      erroXls()
    }

    var array = []
    dataXlsx.map(x => array.push(x.torre))

    var arrId = []
    dataXlsx.map(x => {
      const torre = x.torre
      const idTrr = x.id
      arrId.push({ torre: torre, id: idTrr })
    })

    const projetoNew = { projeto: data.projeto }
    const newArrPro = [...listCm, projetoNew]

    set(ref(db, 'projetos/' + data.projeto), {
      projeto: data.projeto,
      pontos: array,
      processo: data.processo,
      torrepc: arrId,
      especi: "lt",
      municipio: data.municipio
    })

    const projeto = {}
    projeto['projetos/list'] = newArrPro
    await update(ref(db), projeto);

    reset3({})
    cdSucess()
  };

  async function onEdit(data) {
    const array = []
    const size = parseInt(data.quantidade)
    const sizeNumber = size + 1
    for (let i = 1; i < sizeNumber; i++) {
      array.push(data.prefixo + "-" + i)
    }
    //const dataObj = { pontos: array, processo: data.processo, projeto: data.projeto, municipio:data.municipio }
    const projeto = {}

    projeto['projetos/' + data.projeto + '/pontos'] = array
    projeto['projetos/' + data.projeto + '/processo'] = data.processo
    projeto['projetos/' + data.projeto + '/projeto'] = data.projeto
    projeto['projetos/' + data.projeto + '/municipio'] = data.municipio

    await update(ref(db), projeto);
    setProjSelec('')
    setEditProjeto(false)
    setSelecAdd(true)
    //setCdProjeto(true)
    reset({})
    editSucess()
  };

  async function editarCampo(data) {
    setProjSelec(projetoDados[data])
    setSelecAdd(false)
    setCdProjeto(false)
    setCdLinhaT(false)
    setEditProjeto(true)
  }

  async function removerCampo(data) {
    const nameProj = projetoDados[data].projeto

    const filtProj = listCm.filter(x => { return (x.projeto !== nameProj) })
    const projeto = {}
    projeto['projetos/list'] = filtProj
    await update(ref(db), projeto);

    const projetoCd = ref(db, 'projetos/' + nameProj);
    remove(projetoCd)
  }

  useEffect(() => {
    const starCountRef = ref(db, 'projetos/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      const arr = Object.values(data).map(data => {
        if (data.pontos) {
          return { ...data }
        }
      }).filter(Boolean)
      const projetos = []
      for (const key in data) {
        projetos.push(key)
      }
      setProjetoDados(arr)
      setProjetosD(projetos)
      setListCm(data.list)
    });
  }, [])

  async function handleFileUpload(event) {
    if (event.target.files[0] === undefined) {
      setDataXlsx([])
    } else {
      const file = event.target.files[0]
      const f = await file.arrayBuffer();
      const wb = read(f); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const data = utils.sheet_to_json(ws); // generate objects
      setDataXlsx(data)
    }
  }

  const DemoPaper = styled(Paper)(({ theme }) => ({
    width: "100%",
    borderRadius: 10,
    padding: theme.spacing(1.5),
    ...theme.typography.body2,
  }));

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    borderRadius: 10,
    borderColor: "#03045e",
    backgroundColor: "#023e8a",
    '&:hover': {
      backgroundColor: "#03045e",
      borderColor: "#03045e",
    },
    width: '100%'
  }));

  return (
    <div className="card_total">

      <ToastContainer />

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

        {selecAdd &&
          <div style={{ width: 370, marginRight: 15 }}>
            <a className='text_one'>Cadastrar projeto</a>

            <div style={{ marginTop: 10 }}>
              <ColorButton variant="outlined" style={{ marginBottom: 10 }} onClick={() => setCdProjeto(true) || setSelecAdd(false)}>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 700 }}>Projeto comum</Typography>
              </ColorButton>
              <ColorButton variant="outlined" onClick={() => setCdLinhaT(true) || setSelecAdd(false)}>
                <Typography style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 700 }}>Linha de transmissão - LT</Typography>
              </ColorButton>
            </div>
          </div>
        }

        {cdLinhaT &&
          <div className="add_user">
            <div className='card_text'>
              <button onClick={() => setCdLinhaT(false) || setSelecAdd(true)} className='btn_null'><BiArrowBack /></button>
              <a>Voltar</a>
            </div>

            <form onSubmit={handleSubmit3(onSubmit2)}>
              <div className="card_input_user">
                <Controller
                  control={control3}
                  name="projeto"
                  render={({ field: { onChange, value } }) => (
                    <input
                      type="text"
                      className='input'
                      placeholder="Projeto"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors3.projeto && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors3.projeto?.message}</span>}
              </div>

              <div className="card_input_user">
                <Controller
                  control={control3}
                  name="municipio"
                  render={({ field: { onChange, value } }) => (
                    <input
                      type="text"
                      className='input'
                      placeholder="Município - Estado (abreviado)"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors3.municipio && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors3.processo?.municipio}</span>}
              </div>

              <div className="card_input_user">
                <Controller
                  control={control3}
                  name="processo"
                  render={({ field: { onChange, value } }) => (
                    <input
                      type="text"
                      className='input'
                      placeholder="N° do processo"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors3.processo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors3.processo?.message}</span>}
              </div>

              <div className="card_input_tab">
                <input type="file" onChange={handleFileUpload} className="upload_box" />
              </div>

              <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 5 }}>
                  <BiInfoCircle />
                  <a style={{ fontSize: 14, fontWeight: '600', marginLeft: 5 }}>Exemplo </a>
                </div>


                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, width: 650 }}>

                  <table className="table_exp" >
                    <tr>
                      <th className="table_title">torre</th>
                      <th className="table_title">id</th>
                    </tr>
                    <tr>
                      <td className="table_txt">19/2</td>
                      <td className="table_txt">1</td>
                    </tr>
                    <tr>
                      <td className="table_txt">20/1</td>
                      <td className="table_txt">2</td>
                    </tr>
                    <tr>
                      <td className="table_txt">19/2 E 20/1</td>
                      <td className="table_txt">3</td>
                    </tr>
                    <tr>
                      <td className="table_txt">...</td>
                      <td className="table_txt">...</td>
                    </tr>
                  </table>

                  <div style={{ border: "2px solid #ddd", padding: 10, marginLeft: 5, borderRadius: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 400 }}>
                    <a style={{ fontSize: 14, textAlign: 'center', fontWeight: '600' }}>Legenda</a>
                    <a style={{ fontSize: 14, textAlign: 'center' }}>1 - Torre ABCD</a>
                    <a style={{ fontSize: 14, textAlign: 'center' }}>2 - Torre simples</a>
                    <a style={{ fontSize: 14, textAlign: 'center' }}>3 - Entre torres</a>
                  </div>

                  <div style={{ border: "2px solid #ddd", backgroundColor: "#ddd", padding: 10, borderRadius: 10, marginLeft: 5 }}>
                    <a style={{ fontSize: 14, textAlign: 'center' }}>A formatação da tabela deve seguir à risco, como demonstrado ao lado.</a>
                  </div>

                  {/* */}
                </div>
              </div>

              <div className="card_input_user">
                <button type="submit" className="btn_cd" >Cadastrar</button>
              </div>
            </form>
          </div>
        }

        {editProjeto &&
          <div style={{ width: '100%', marginRight: 25 }}>
            <a className='text_one'>Editar projeto</a>

            <DemoPaper variant="outlined"
              style={{ marginTop: 8, width: '100%', marginRight: 25 }}>
              <div className='card_text'>
                <button onClick={() => setEditProjeto(false) || setSelecAdd(true)} className='btn_null'><BiArrowBack /></button>
                <a>Voltar</a>
              </div>
              <form onSubmit={handleSubmit2(onEdit)}>
                <div className="card_input_user">
                  <Controller
                    control={control2}
                    name="projeto"
                    defaultValue={projSelec.projeto}
                    render={({ field: { onChange } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Projeto"
                        defaultValue={projSelec.projeto}
                        //value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors2.projeto && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.projeto?.message}</span>}
                </div>

                <div className="card_input_user">
                  <Controller
                    control={control2}
                    name="municipio"
                    defaultValue={projSelec.municipio}
                    render={({ field: { onChange } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Município"
                        defaultValue={projSelec.municipio}
                        //value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors2.municipio && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.municipio?.message}</span>}
                </div>

                <div className="card_input_user">
                  <Controller
                    control={control2}
                    name="processo"
                    defaultValue={projSelec.processo}
                    render={({ field: { onChange } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="N° do processo"
                        defaultValue={projSelec.processo}
                        //value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors2.processo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.processo?.message}</span>}
                </div>

                <div className="card_input_user">
                  <Controller
                    control={control2}
                    name="prefixo"
                    defaultValue={projSelec.pontos[0].substring(0, 2)}
                    render={({ field: { onChange } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Prefixo"
                        defaultValue={projSelec.pontos[0].substring(0, 2)}
                        //value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors2.prefixo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.prefixo?.message}</span>}
                </div>

                <div className="card_input_user">
                  <Controller
                    control={control2}
                    name="quantidade"
                    defaultValue={projSelec.pontos.length}
                    render={({ field: { onChange } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Quantidade"
                        defaultValue={projSelec.pontos.length}
                        //value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors2.quantidade && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.quantidade?.message}</span>}
                </div>

                <div className="card_input_user">
                  <button type="submit" className="btn_cd" >Cadastrar</button>
                </div>
              </form>
            </DemoPaper>
          </div>
        }

        {cdProjeto &&
          <div style={{ width: '100%', marginRight: 25 }}>
            <a className='text_one'>Cadastrar projeto - Poço Teste (PT)</a>
            <DemoPaper variant="outlined"
              style={{ marginTop: 8, width: '100%', marginRight: 25 }}>
              <div className='card_text'>
                <button onClick={() => setCdProjeto(false) || setSelecAdd(true)} className='btn_null'><BiArrowBack /></button>
                <a>Voltar</a>
              </div>

              <form onSubmit={handleSubmit(onSubmitPT)}>
                <div className="card_input_user">
                  <Controller
                    control={control}
                    name="projeto"
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Projeto"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.projeto && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.projeto?.message}</span>}
                </div>

                <div className="card_input_user">
                  <Controller
                    control={control}
                    name="municipio"
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Município - Estado (abreviado)"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.municipio && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.municipio?.message}</span>}
                </div>

                <div className="card_input_user">
                  <Controller
                    control={control}
                    name="processo"
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="N° do processo"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.processo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.processo?.message}</span>}
                </div>



                <div className="card_input_user">
                  <Controller
                    control={control}
                    name="tipo"
                    render={({ field: { onChange, value } }) => (
                      <div style={{ marginBottom: '8px' }}>

                        <label style={{ fontSize: 16, marginRight: 15 }}>
                          <input
                            type="radio"
                            value="nm"
                            checked={value === "nm"}
                            onChange={onChange}
                            style={{ marginRight: 4 }}
                          />
                          Poço Teste
                        </label>

                        <label style={{ fontSize: 16, marginRight: 15 }}>
                          <input
                            type="radio"
                            value="pv"
                            checked={value === "pv"}
                            onChange={onChange}
                            style={{ marginRight: 4 }}
                          />
                          Ponto de Vistoria
                        </label>

                      </div>
                    )}
                  />
                  {errors.tipo && (<span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.tipo?.message}</span>)}
                </div>

                {/* 

                <div className="card_input_user">
                  <Controller
                    control={control}
                    name="prefixo"
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Prefixo"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.prefixo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.prefixo?.message}</span>}
                </div>

                */}

                <div className="card_input_user">
                  <Controller
                    control={control}
                    name="quantidade"
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Quantidade"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.quantidade && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.quantidade?.message}</span>}
                </div>

                <div className="card_input_user">
                  <button type="submit" className="btn_cd" >Cadastrar</button>
                </div>
              </form>
            </DemoPaper>
          </div>
        }

        {selecAdd &&
          <div style={{ marginRight: 25, width: '100%' }}>

            <a className='text_one'>Projetos</a>

            {projetoDados &&
              projetoDados.map((i, index) => (
                <DemoPaper variant="outlined" key={i}
                  style={{ display: 'flex', marginRight: 15, marginBottom: 8, marginTop: 8, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <a>{i.projeto}</a>
                    <a className="selo_proj">{i.pontos.length} - PTs</a>
                  </div>
                  <div>
                    <button className="btn_edit_one" onClick={() => editarCampo(index)}>Editar</button>
                    <button className="btn_edit_final" onClick={() => removerCampo(index)}>Excluir</button>
                  </div>
                </DemoPaper>
              ))
            }
          </div>
        }

      </div>

    </div>
  )
}

export default Campos;