import React, { useEffect, useState, useRef, useCallback, PureComponent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../services/firebaseConnection'
import { ref as ref_database, set, child, get, update, remove } from "firebase/database";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow  } from '@react-google-maps/api';
import './styles.css';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { RiDeleteBinLine } from "react-icons/ri";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ToastContainer, toast } from 'react-toastify';

import { PieChart, Pie, Cell, Legend, BarChart, XAxis, YAxis, Bar, AreaChart, Area, ComposedChart, Label, Tooltip, Line, LineChart, CartesianGrid } from 'recharts';

const dbRef = ref_database(db);

function InfoCampo() {

    const [selectUser, setSelectUser] = useState('');

    const handleChangeUser = (event) => {
        setSelectUser(event.target.value);
    };

    const sucessBackup = () => toast.success('Backup realizado com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const errorPTdupl = () => toast.error('Os usuários sincronizaram informações duplicadas! Ver mais...', {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: { width: '400px' }
    });

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const [openAlert, setOpenAlert] = useState(false);

    const handleClickOpen = () => {
        setOpenAlert(true);
    };

    const handleClickClose = () => {
        setOpenAlert(false);
    };

    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    const [pontos, setPontos] = useState()
    const [centro, setCentro] = useState()
    const [usersData, setUsers] = useState()
    const [task, setTask] = useState()
    const [campo, setCampo] = useState()
    const [syncTime, setSyncTime] = useState()
    const [totalLT, setTotalLT] = useState()
    const [PTFin, setPTFin] = useState()
    const [notPT, setNotPT] = useState()
    const [taskRepetidas, setTaskRepetidas] = useState()
    const [taskTotal, setTaskTotal] = useState()
    const [PTDuplicados, setPTDuplicados] = useState()
    const [infoDataCampo, setInfoDataCampo] = useState(false)
    const [infoUser, setInfoUser] = useState()


    const location = useLocation();
    const projeto = location.state.projeto

    const center = {
        lat: -5.071833,
        lng: -42.8191367
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBljCZj07IFHa45qevD3tQp0_9gmnC7I3I"
        
    })

    //"AIzaSyBBJUD5A-ZBAT-FrH502lNABqAXv8Yv_bU"

    const [map, setMap] = useState(null)

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds)
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    function pontoCentral(coordenadas) {
        // Inicializar variáveis para calcular as médias
        let somaLat = 0;
        let somaLong = 0;

        // Iterar sobre as coordenadas para calcular a soma de lat e long
        coordenadas.forEach(coordenada => {
            somaLat += coordenada.lat; // Acesso à latitude da coordenada
            somaLong += coordenada.long; // Acesso à longitude da coordenada
        });

        // Calcular as médias de lat e long
        const mediaLat = (somaLat / coordenadas.length).toFixed(7)
        const mediaLong = (somaLong / coordenadas.length).toFixed(6)

        // Retornar o ponto central como um objeto {lat, long}
        return { lat: mediaLat * 1, lng: mediaLong * 1 };
    }

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const styleModalError = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function agruparPorDia(objetoDeObjetos) {
        const agrupado = [];

        // Iterar sobre cada chave do objeto
        Object.keys(objetoDeObjetos).forEach(chave => {
            // Verificar se a chave entorno existe e se é um objeto
            if (objetoDeObjetos[chave].entorno && typeof objetoDeObjetos[chave].entorno === 'object') {
                // Extrair o timestamp da chave entorno
                const timestamp = objetoDeObjetos[chave].entorno.timestamp;
                // Converter o timestamp para a data correspondente (dia)
                const data = new Date(timestamp);
                const dia = data.toISOString().slice(0, 10); // Obtém a data no formato YYYY-MM-DD

                // Verificar se já existe uma entrada para este dia
                const foundIndex = agrupado.findIndex(item => item.name === dia);
                if (foundIndex !== -1) {
                    // Se a entrada já existe, incrementar o valor
                    agrupado[foundIndex].value++;
                } else {
                    // Se não existir, criar uma nova entrada no objeto agrupado
                    agrupado.push({ name: dia, value: 1 });
                }
            }
        });

        // Ordenar o array agrupado pela data
        agrupado.sort((a, b) => new Date(a.name) - new Date(b.name));

        return agrupado;
    }

    useEffect(() => {

        const dbRef = ref_database(db);
        get(child(dbRef, 'projetos/' + projeto)).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                if (dados.especi === "lt") {

                    const dataTorre = dados.torrepc
                    const resultado = dataTorre.reduce((acc, obj) => {
                        if (obj.id === 1) {
                            return acc + 5;
                        } else {
                            return acc + 1;
                        }
                    }, 0)

                    setTotalLT(resultado)
                }

                setCampo(dados)
                var arrData = {}
                var arrUser = {}
                var arrTask = {}
                var timeSync = {}

                const promises = (dados?.users || []).map((x) => {
                    const data = dados?.campo?.[x];

                    if (data) {
                        arrUser[data.dataUser] = data.dados;
                        arrTask[data.dataUser] = dados?.task?.[data.dataUser];
                        timeSync[data.dataUser] = data.dataSync;
                    }
                });

                /*const promises = dados.users.map((x) => {
                    return get(child(dbRef, 'usuarios/' + x)).then((snapshot) => {
                        if (snapshot.exists()) {
                            const data = snapshot.val()
                            arrUser[data.dataUser] = data.dados
                            arrTask[data.dataUser] = dados.task[data.dataUser]
                            timeSync[data.dataUser] = data.dataSync
                        }
                    })
                })*/

                Promise.all(promises).then(() => {
                    /*var totalPontos = Object.values(arrUser).reduce((acc, curr) => {
                        Object.assign(acc, curr);
                        return acc;
                    }, {})*/
                    var totalPontos = Object.entries(arrUser).reduce((acc, [user, value]) => {
                        Object.entries(value).forEach(([point, data]) => {
                            const pointKey = `${point}`;
                            acc[pointKey] = { ...data, user };
                        });
                        return acc;
                    }, {});

                    var arrPTCon = []

                    Object.values(totalPontos).map((x) => {
                        if (x.encerramento) {
                            arrPTCon.push(x.entorno.pt)
                        } else if (x.intransponibilidade) {
                            arrPTCon.push(x.entorno.pt)
                        }
                    })

                    const pontosTotais = dados.pontos

                    const repeticoesPT = {};

                    for (const usuario in arrUser) {
                        const pts = Object.keys(arrUser[usuario]);
                        pts.forEach(pt => {
                            if (repeticoesPT[pt]) {
                                repeticoesPT[pt].push(usuario);
                            } else {
                                repeticoesPT[pt] = [usuario];
                            }
                        });
                    }

                    const ptRepetidos = {}
                    for (const pt in repeticoesPT) {
                        if (repeticoesPT[pt].length > 1) {
                            ptRepetidos[pt] = repeticoesPT[pt];
                        }
                    }

                    if (Object.keys(ptRepetidos).length > 0) {
                        setPTDuplicados(ptRepetidos)
                        setTimeout(() => setOpenAlertTask(true), 1100)
                    }

                    const pontosAgrupadosPorDia = agruparPorDia(totalPontos);

                    setPTFin(arrPTCon)
                    setPontos(totalPontos)
                    setUsers(arrUser)
                    setTask(arrTask)
                    setSyncTime(timeSync)

                    const notPT = pontosTotais.filter(item => !arrPTCon.includes(item))

                    setNotPT(notPT)

                    var arrTaskTotal = Object.values(arrTask).flatMap(task => Object.keys(task));

                    var counts = {};
                    arrTaskTotal.forEach(key => {
                        counts[key] = (counts[key] || 0) + 1
                    });

                    var repeatedKeys = Object.keys(counts).filter(key => counts[key] > 1)

                    if (repeatedKeys.length > 0) {
                        const resultado = {};

                        for (const chave in arrTask) {
                            const valor = arrTask[chave];
                            for (const item of repeatedKeys) {
                                if (valor.hasOwnProperty(item)) {
                                    if (!resultado[item]) {
                                        resultado[item] = [];
                                    }
                                    resultado[item].push(chave);
                                }
                            }
                        }
                        setTaskRepetidas(resultado)
                        setTimeout(() => setOpenAlertTask(true), 1100)
                    }

                    setTaskTotal(arrTaskTotal)

                    var arrCoords = []

                    Object.values(totalPontos).map((x) => {
                        if (x.entorno) {
                            if (x.entorno.latitude) {
                                const coords = { lat: x.entorno.latitude, long: x.entorno.longitude }
                                arrCoords.push(coords)
                            }
                        }
                    })
                    const pontoCentralObjeto = pontoCentral(arrCoords);
                    setInfoDataCampo(true)
                    setCentro(pontoCentralObjeto)
                    handleClose()
                })
            }
        })

    }, [])

    const DemoPaper = styled(Paper)(({ theme }) => ({
        width: 120,
        padding: theme.spacing(0.5),
        ...theme.typography.body2,
        textAlign: 'center',
    }));

    const PaperPonto = styled(Paper)(({ theme }) => ({
        width: '22vw',
        padding: theme.spacing(0.5),
        ...theme.typography.body2,
    }));

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#2a9d8f"),
        borderColor: "#287271",
        backgroundColor: "#2a9d8f",
        '&:hover': {
            backgroundColor: "#287271",
            borderColor: "#287271",
        },
        width: '100%'
    }));

    const ButtonOne = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#2a9d8f"),
        borderColor: "#144560",
        backgroundColor: "#245672",
        '&:hover': {
            backgroundColor: "#144560",
            borderColor: "#144560",
        },
        width: 30,
        padding: 3
    }));

    async function abrirUserSel() {
        setOpenAlert(true)
    }

    async function backup() {
        if (selectUser) {

            set(ref_database(db, 'backup/' + projeto), {
                dados: pontos,
                //dadosPV: mergedUsersPV,
                projeto: projeto,
                //summary: summary,
                protocolo: campo.processo,
                municipio: campo.municipio,
                executor: selectUser,
                refresh: true
            }).then(async () => {

                const fetchData = async () => {
                    try {
                        const timestampBk = await get(child(dbRef, 'timestampsync/'));
                        let dataTimestampBk
                        if (timestampBk.exists()) {
                            dataTimestampBk = timestampBk.val();
                        }

                        dataTimestampBk[projeto] = new Date().getTime()

                        const updades = {}
                        updades['timestampsync'] = dataTimestampBk
                        await update(ref_database(db), updades);

                    } catch (error) {
                        console.error(error);
                    }
                };

                fetchData();
                sucessBackup()
                setOpenAlert(false)

            })

        }
    }

    const [openPT, setOpenPT] = useState(false);
    const [openAlertTask, setOpenAlertTask] = useState(false);
    const handleOpenPT = () => setOpenPT(true);
    const handleClosePT = () => setOpenPT(false);

    const [clickedMarkerIndex, setClickedMarkerIndex] = useState(); // Estado para armazenar o índice do marcador clicado

    /*const handleMarkerClick = (index) => {
        const centerCoords = { lat: index.entorno.latitude, long: index.entorno.longitude }
        console.log(centerCoords)
        setClickedMarkerIndex(index)
        setCentro(centerCoords)
    };*/

    const handleMarkerClick = (marker) => {

        if (marker.x) {
            setCentro({ lat: marker.x.entorno.latitude, lng: marker.x.entorno.longitude })
            setInfoDataCampo(false)
            setInfoUser('')
            setClickedMarkerIndex(marker)
        } else {
            setCentro({ lat: marker.entorno.latitude, lng: marker.entorno.longitude })
            setInfoDataCampo(false)
            setInfoUser('')
            const data = { x: marker, user: marker.user }
            setClickedMarkerIndex(data)
        }

    };

    async function deletePTUser(data) {
        const dbRef = ref_database(db);
        get(child(dbRef, 'users/')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = Object.values(snapshot.val())
                const uid = dados.filter((x) => x.username === data.user).map((x) => x.uid)
                const childRef = ref_database(db, 'projetos/' + projeto + "/campo/" + uid[0] + "/dados/" + data.pt)
                remove(childRef)
            }
        })

        const newData = { ...usersData }
        delete newData[data.user][data.pt]
        setUsers(newData)
        setInfoDelete()
        setOpenDeletePT(false)
    }

    const [openDeletePT, setOpenDeletePT] = useState(false);
    const [infoDelete, setInfoDelete] = useState();

    const clickOpenDeletePT = () => {
        setOpenDeletePT(true)
    }

    const formatarTextoParaCopiar = () => {
        let textoFormatado = "*⚠️ INTERVENÇÔES DUPLICADAS: ⚠️*\n\n";
        for (const chave in PTDuplicados) {
            textoFormatado += `${chave}: ${PTDuplicados[chave].join(", ")}\n`;
        }
        return textoFormatado;
    };

    const copiarParaAreaDeTransferencia = () => {
        const texto = formatarTextoParaCopiar();
        navigator.clipboard.writeText(texto)
            .then(() => {
                alert("Texto copiado para a área de transferência!");
            })
            .catch(err => {
                console.error('Erro ao copiar texto: ', err);
            });
    }

    const copiarPontosPendentes = () => {
        const textoFormatado = `*⚠️ INTERVENÇÔES PENDENTES: ⚠️*\n\n${notPT}`
        navigator.clipboard.writeText(textoFormatado)
            .then(() => {
                alert("Texto copiado para a área de transferência!");
            })
            .catch(err => {
                console.error('Erro ao copiar texto: ', err);
            });
    }

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const COLORS = ['#59cd90', '#ee6352'];

    return (
        <div style={{ margin: 15 }}>

            <ToastContainer />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                open={openAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Selecione o usuário responsável pelo campo"}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth style={{ marginTop: 15 }}>
                        <InputLabel id="demo-simple-select-label">Usuários</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectUser}
                            label="Usuários"
                            onChange={handleChangeUser}
                        >
                            {usersData &&
                                Object.keys(usersData).map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index}>{item}</MenuItem>
                                    )
                                })}

                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => backup()}>Concluir backup</Button>
                    <Button onClick={() => handleClickClose()}>Fechar</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeletePT}
                onClose={() => setOpenDeletePT(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Deseja excluir a interveção?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {infoDelete && infoDelete.pt && (
                            <span>Você está prestes a excluir a Intervenção {infoDelete.pt}, executado pelo usuário {infoDelete.user}.</span>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => deletePTUser({ pt: infoDelete.pt, user: infoDelete.user })}>Sim</Button>
                    <Button onClick={() => setOpenDeletePT(false)}>Não</Button>
                </DialogActions>
            </Dialog>

            <Modal
                open={openPT}
                onClose={handleClosePT}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>

                    <a style={{ fontSize: 16, fontWeight: 600 }}>Pontos não concluídos</a>

                    {notPT &&
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15, alignItems: 'center' }}>
                            <a style={{ fontSize: 14, fontWeight: 500 }}>{notPT.length} Intervenções</a>
                            <ButtonOne onClick={copiarPontosPendentes} style={{ textTransform: 'none', marginLeft: 8 }}>
                                <Typography style={{ fontSize: 12, fontWeight: 500 }}>Copiar</Typography>
                            </ButtonOne>
                        </div>
                    }

                    <div style={{ overflowY: 'scroll', height: 120, marginTop: 15 }}>
                        {notPT &&
                            notPT.map((item, index) => {
                                return (
                                    <div key={index}
                                        style={{
                                            backgroundColor: '#e9ecef', padding: 6, borderRadius: 10, marginBottom: 10,
                                            display: 'flex', justifyContent: 'center', marginRight: 10
                                        }}>
                                        <a style={{ fontSize: 14, textAlign: 'center' }}>{item}</a>
                                    </div>
                                )
                            })}
                    </div>

                </Box>
            </Modal>

            <Modal
                open={openAlertTask}
                onClose={() => setOpenAlertTask(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModalError}>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <div style={{ width: 290, marginRight: 20 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <WarningAmberRoundedIcon sx={{ fontSize: 40, color: '#9e2a2b' }} />
                                <a style={{ fontSize: 18, fontWeight: 600, color: '#9e2a2b' }}>Tarefas duplicadas</a>
                            </div>

                            {taskRepetidas &&
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                                    <a style={{ fontSize: 14, fontWeight: 500 }}>{Object.keys(taskRepetidas).length} Intervenções</a>
                                </div>
                            }

                            <div style={{ overflowY: 'scroll', height: 120, marginTop: 15 }}>
                                {taskRepetidas &&
                                    Object.entries(taskRepetidas).map((item, index) => {
                                        return (
                                            <div key={index}
                                                style={{
                                                    backgroundColor: '#e9ecef', padding: 6, borderRadius: 10, marginBottom: 10,
                                                    display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 10
                                                }}>

                                                <div style={{ backgroundColor: '#fff', padding: 8, borderRadius: 8 }}>
                                                    <a style={{ fontSize: 14, textAlign: 'center', fontWeight: 600 }}>{item[0]}</a>
                                                </div>

                                                <div>
                                                    {item[1] &&
                                                        item[1].map((x, y) => {
                                                            return (
                                                                <div key={y} style={{ fontSize: 14, textAlign: 'center' }}>{x}</div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        )
                                    })}
                            </div>

                        </div>

                        <div style={{ width: 290 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <WarningAmberRoundedIcon sx={{ fontSize: 40, color: '#9e2a2b' }} />
                                <a style={{ fontSize: 18, fontWeight: 600, color: '#9e2a2b' }}>Intervenções repetidas</a>
                            </div>

                            {PTDuplicados &&
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15, alignItems: 'center' }}>
                                    <a style={{ fontSize: 14, fontWeight: 500 }}>{Object.keys(PTDuplicados).length} Intervenções</a>
                                    <ButtonOne onClick={copiarParaAreaDeTransferencia} style={{ textTransform: 'none', marginLeft: 8 }}>
                                        <Typography style={{ fontSize: 12, fontWeight: 500 }}>Copiar</Typography>
                                    </ButtonOne>
                                </div>
                            }

                            <div style={{ overflowY: 'scroll', height: 120, marginTop: 15 }}>

                                {PTDuplicados &&
                                    Object.entries(PTDuplicados).map((item, index) => {
                                        return (
                                            <div key={index} style={{
                                                backgroundColor: '#e9ecef', padding: 6, borderRadius: 10, marginBottom: 10,
                                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 10
                                            }}>

                                                <div style={{ backgroundColor: '#fff', padding: 8, borderRadius: 8 }}>
                                                    <a style={{ fontSize: 14, textAlign: 'center', fontWeight: 600 }}>{item[0]}</a>
                                                </div>

                                                <div>
                                                    {item[1] &&
                                                        item[1].map((x, y) => {
                                                            return (
                                                                <div key={y} style={{ fontSize: 14, textAlign: 'center' }}>{x}</div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                        <a style={{ fontSize: 12 }}>Observação: Por favor, consulte os usuários mencionados acima para entender por que há Intervenções compartilhadas e/ou repetidas.</a>
                    </div>

                </Box>
            </Modal>

            <div style={{ marginBottom: 10 }}>
                <a style={{ fontSize: 20, fontWeight: 600 }}>{projeto}</a>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>

                {infoDataCampo &&
                    <div style={{ flex: 1, marginRight: 15 }}>

                        {campo &&
                            <div style={{ marginRight: 10, marginBottom: 15 }}>

                                {totalLT ?
                                    <DemoPaper variant="outlined" style={{ width: "100%", marginBottom: 4 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 3, paddingLeft: 10, paddingRight: 10 }}>
                                            <Typography variant="subtitle2">Intervenções</Typography>
                                            <Typography variant="button">{totalLT}</Typography>
                                        </div>
                                    </DemoPaper>
                                    :
                                    <DemoPaper variant="outlined" style={{ width: "100%", marginBottom: 4, backgroundColor: '#1e6091' }}>
                                        <div style={{
                                            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                            padding: 3, paddingLeft: 10, paddingRight: 10
                                        }}>

                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Typography variant="subtitle2" sx={{ color: '#fff' }}>Intervenções executadas</Typography>
                                                    {PTDuplicados &&
                                                        <div onClick={() => setOpenAlertTask(true)}
                                                            style={{ marginLeft: 5, cursor: 'pointer' }}>
                                                            <ReportProblemRoundedIcon sx={{ fontSize: 20, color: '#e1403d' }} />
                                                        </div>
                                                    }
                                                </div>

                                                <div style={{
                                                    width: '100%',
                                                    height: 20,
                                                    backgroundColor: '#1a759f',
                                                    marginTop: 15,
                                                    borderRadius: 8,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'start',
                                                    padding: 3,
                                                    marginBottom: 5
                                                }}>
                                                    <div style={{
                                                        width: `${((campo.pontos.length - notPT.length) / campo.pontos.length) * 100}%`,
                                                        height: 17,
                                                        backgroundColor: '#34a0a4',
                                                        borderRadius: 7,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <a style={{ fontSize: 10, color: '#fff' }}>
                                                            {(((campo.pontos.length - notPT.length) / campo.pontos.length) * 100).toFixed(0)}%
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>

                                            <div style={{ alignContent: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ flexDirection: 'row' }}>
                                                        <a style={{ fontSize: 30, color: '#fff', fontWeight: 600 }}>{(campo.pontos).length - notPT.length}</a>
                                                        <a style={{ fontSize: 14, color: '#fff', }}> /{(campo.pontos).length}</a>
                                                    </div>
                                                </div>

                                                <ButtonOne onClick={() => handleOpenPT()} style={{ textTransform: 'none', marginLeft: 8, width: '100%' }}>
                                                    <Typography style={{ fontSize: 12, fontWeight: 500 }}>Em aberto</Typography>
                                                </ButtonOne>
                                            </div>

                                        </div>
                                    </DemoPaper>
                                }

                                {totalLT &&
                                    <DemoPaper variant="outlined" style={{ width: "100%", marginBottom: 4, backgroundColor: '#f2ccc3', color: '#c52233' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 3, paddingLeft: 10, paddingRight: 10 }}>
                                            <Typography variant="subtitle2">Não concluídos</Typography>
                                            {pontos &&
                                                <Typography variant="button">{totalLT - PTFin.length}</Typography>
                                            }
                                        </div>
                                    </DemoPaper>
                                }

                                {taskTotal &&

                                    <DemoPaper variant="outlined" style={{ width: "100%", marginBottom: 4, backgroundColor: '#168aad' }}>
                                        <div style={{
                                            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                            padding: 3, paddingLeft: 10, paddingRight: 10
                                        }}>

                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Typography variant="subtitle2" sx={{ color: '#fff' }}>Atarefados</Typography>
                                                {taskTotal.length > (campo.pontos).length &&
                                                    <div onClick={() => setOpenAlertTask(true)}
                                                        style={{ marginLeft: 5, cursor: 'pointer' }}>
                                                        <ReportProblemRoundedIcon sx={{ fontSize: 20, color: '#e1403d' }} />
                                                    </div>
                                                }
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ flexDirection: 'row' }}>
                                                    <a style={{ fontSize: 30, color: '#fff', fontWeight: 600 }}>{taskTotal.length}</a>
                                                    <a style={{ fontSize: 14, color: '#fff', }}> /{(campo.pontos).length}</a>
                                                </div>
                                            </div>

                                        </div>
                                    </DemoPaper>
                                }

                                <DemoPaper variant="outlined" style={{ width: "100%", marginBottom: 4 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 3, paddingLeft: 10, paddingRight: 10 }}>
                                        <Typography variant="subtitle2">Processo</Typography>
                                        <Typography variant="button">{campo.processo}</Typography>
                                    </div>
                                </DemoPaper>

                                <DemoPaper variant="outlined" style={{ width: "100%", marginBottom: 4 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 3, paddingLeft: 10, paddingRight: 10 }}>
                                        <Typography variant="subtitle2">Município</Typography>
                                        <Typography variant="subtitle2">{campo.municipio}</Typography>
                                    </div>
                                </DemoPaper>

                            </div>
                        }

                        <Divider style={{ marginTop: 10, marginBottom: 5, fontWeight: 500 }}>Usuários</Divider>

                        <div style={{ marginRight: 10 }}>
                            {usersData &&
                                Object.entries(usersData)
                                    .map((item, index) => {
                                        const panelId = `panel${index + 1}`;
                                        const controlId = `panel${index + 1}-content`;
                                        let falta = null;
                                        if (task && item && item[0] && task[item[0]]) {
                                            falta = Object.keys(task[item[0]])
                                                .filter(value => !Object.keys(item[1]).includes(value))
                                                .map(filteredValue => (filteredValue));
                                        }

                                        return {
                                            index,
                                            item,
                                            falta,
                                            length: Object.values(item[1]).length
                                        };
                                    })
                                    .sort((a, b) => b.length - a.length) // Ordena com base no comprimento
                                    .map(({ index, item, falta }, sortedIndex) => (
                                        <DemoPaper
                                            variant="outlined"
                                            style={{ width: "100%", marginBottom: 4, cursor: 'pointer' }}
                                            key={index}
                                            onClick={() => {
                                                setInfoUser({ item: item, falta: falta });
                                                setInfoDataCampo(false);
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    padding: 3,
                                                    paddingLeft: 3,
                                                    paddingRight: 10,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ backgroundColor: '#cbf3f0', padding: 5, borderRadius: 5, marginRight: 8, width: 20 }}>
                                                        <a style={{ fontWeight: 600 }}>{sortedIndex + 1}°</a>
                                                    </div>

                                                    <Typography variant="subtitle2">{item[0]}</Typography>
                                                </div>

                                                <div>
                                                    <a style={{ fontSize: 18, fontWeight: 600 }}>{Object.values(item[1]).length} </a>
                                                    <a style={{ fontSize: 14 }}>/{Object.keys(task[item[0]]).length}</a>
                                                </div>


                                            </div>
                                        </DemoPaper>
                                    ))
                            }
                        </div>

                        <div style={{ marginTop: 10 }} >
                            <ColorButton variant="outlined" onClick={() => abrirUserSel()}>Fazer backup</ColorButton>
                        </div>

                    </div>
                }

                {clickedMarkerIndex &&
                    <PaperPonto variant="outlined" style={{ marginRight: 15 }} >

                        <div style={{ padding: 10 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{
                                    flex: 1, display: 'flex', flexDirection: 'row', padding: 3, borderRadius: 5, alignItems: 'center',
                                    justifyContent: 'center', backgroundColor: '#edede9'
                                }}>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>{clickedMarkerIndex.x.entorno.pt}</Typography>
                                </div>

                                <ColorButton variant="outlined" style={{ width: 20, marginLeft: 10 }} onClick={() => {
                                    setClickedMarkerIndex("")
                                    setInfoDataCampo(true)
                                }}>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 700, fontSize: 12 }}>Fechar</Typography>
                                </ColorButton>
                            </div>

                            <div style={{ overflowY: 'scroll', height: '80vh', marginRight: -5, marginTop: 10 }}>

                                <div style={{ marginRight: 15 }}>

                                    <div style={{
                                        display: 'flex', width: '100%', flexDirection: 'row', padding: 3,
                                        justifyContent: 'center', backgroundColor: '#0b5266', borderRadius: 5
                                    }}>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#fff' }}>{clickedMarkerIndex.user}</Typography>
                                    </div>

                                    <div style={{
                                        display: 'flex', width: '100%', flexDirection: 'row', padding: 3,
                                        justifyContent: 'center', backgroundColor: '#edede9', borderRadius: 5, marginTop: 5
                                    }}>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Entorno</Typography>
                                    </div>

                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Coordenada</Typography>
                                    <Typography variant="subtitle2">{clickedMarkerIndex.x.entorno.utm}</Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Vegetação</Typography>
                                    <Typography variant="subtitle2">{clickedMarkerIndex.x.entorno.vegetacao}</Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Declividade</Typography>
                                    <Typography variant="subtitle2">{clickedMarkerIndex.x.entorno.declividade}</Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Feição de relevo</Typography>
                                    <Typography variant="subtitle2">{clickedMarkerIndex.x.entorno.feicRelevo}</Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Posição topográfica</Typography>
                                    <Typography variant="subtitle2">{clickedMarkerIndex.x.entorno.posicaoTop}</Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Contextualização ambiental</Typography>
                                    <Typography variant="subtitle2">{clickedMarkerIndex.x.entorno.descricao}</Typography>


                                    {clickedMarkerIndex.x.pedologia &&
                                        <div style={{
                                            display: 'flex', width: '100%', flexDirection: 'row', padding: 3,
                                            justifyContent: 'center', backgroundColor: '#edede9', borderRadius: 5, marginTop: 5
                                        }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Pedologia</Typography>
                                        </div>
                                    }

                                    {clickedMarkerIndex.x.pedologia &&
                                        Object.entries(clickedMarkerIndex.x.pedologia)
                                            .filter((x) => x[0] !== "pt")
                                            .map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Typography variant="subtitle2">Pacote - {item[0]} cm</Typography>
                                                        <Typography variant="subtitle2" sx={{ display: 'flex', flexDirection: 'row' }}><Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Coloração:</Typography>{item[1].coloracao}</Typography>
                                                        <Typography variant="subtitle2" sx={{ display: 'flex', flexDirection: 'row' }}><Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Textura:</Typography>{item[1].textura}</Typography>
                                                    </div>
                                                )
                                            })
                                    }

                                    {clickedMarkerIndex.x.encerramento &&
                                        <div>
                                            <div style={{
                                                display: 'flex', width: '100%', flexDirection: 'row', padding: 3,
                                                justifyContent: 'center', backgroundColor: '#edede9', borderRadius: 5, marginTop: 5
                                            }}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Encerramento</Typography>
                                            </div>

                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Motivo</Typography>
                                            <Typography variant="subtitle2">{clickedMarkerIndex.x.encerramento.encerramento}</Typography>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Observação</Typography>
                                            <Typography variant="subtitle2">{clickedMarkerIndex.x.encerramento.observacoes}</Typography>

                                        </div>
                                    }

                                    {clickedMarkerIndex.x.intransponibilidade &&
                                        <div>
                                            <div style={{
                                                display: 'flex', width: '100%', flexDirection: 'row', padding: 3,
                                                justifyContent: 'center', backgroundColor: '#edede9', borderRadius: 5, marginTop: 5
                                            }}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Intransponibilidade</Typography>
                                            </div>

                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Motivo</Typography>
                                            <Typography variant="subtitle2">{clickedMarkerIndex.x.intransponibilidade.motivo}</Typography>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Observação</Typography>
                                            <Typography variant="subtitle2">{clickedMarkerIndex.x.intransponibilidade.observacoes}</Typography>

                                        </div>
                                    }

                                    {clickedMarkerIndex.x.fotos &&
                                        <div style={{
                                            display: 'flex', width: '100%', flexDirection: 'row', padding: 3,
                                            justifyContent: 'center', backgroundColor: '#edede9', borderRadius: 5, marginTop: 5
                                        }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>Fotos</Typography>
                                        </div>
                                    }

                                    {clickedMarkerIndex.x.fotos &&
                                        Object.entries(clickedMarkerIndex.x.fotos)
                                            .filter((x) => x[0] !== "pt")
                                            .map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Typography variant="subtitle2">{item[0]}</Typography>
                                                        <img src={item[1].uriStorage} style={{ width: '100%', height: 'auto', borderRadius: 10 }} />
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </div>

                        </div>
                    </PaperPonto>
                }

                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    {isLoaded ? (
                        <div>
                            {centro !== "" &&
                                <GoogleMap
                                    mapContainerStyle={{ height: infoUser ? '50vh' : '90vh', width: clickedMarkerIndex ? '58vw' : infoUser ? '82vw' : '58vw', borderRadius: 10 }}
                                    center={centro}
                                    options={{
                                        mapTypeId: 'satellite',
                                        streetViewControl: false,
                                        maxZoom: 20,
                                        minZoom: 8,
                                    }}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}

                                >
                                    {pontos &&
                                        Object.values(pontos).map((item, index) => {
                                            return (
                                                <div key={index}>

                                                    {item.encerramento &&
                                                        <Marker
                                                            position={{ lat: item.entorno.latitude, lng: item.entorno.longitude }}
                                                            onClick={() => handleMarkerClick(item)}
                                                            icon={{
                                                                path: window.google.maps.SymbolPath.CIRCLE,
                                                                fillColor: '#06d6a0',
                                                                fillOpacity: 1,
                                                                strokeColor: '#283618',
                                                                strokeWeight: 1,
                                                                scale: 8
                                                            }}
                                                            options={{
                                                                label: clickedMarkerIndex && clickedMarkerIndex.x.entorno.pt === item.entorno.pt ? {
                                                                    text: item.entorno.pt,
                                                                    fontSize: '14px',
                                                                    fontWeight: '600',
                                                                    className: 'text_marker'
                                                                } : null
                                                            }}
                                                        >
                                                        </Marker>
                                                    }

                                                    {item.intransponibilidade &&
                                                        <Marker
                                                            position={{ lat: item.entorno.latitude, lng: item.entorno.longitude }}
                                                            onClick={() => handleMarkerClick(item)}
                                                            icon={{
                                                                path: window.google.maps.SymbolPath.CIRCLE,
                                                                fillColor: '#06d6a0',
                                                                fillOpacity: 1,
                                                                strokeColor: '#283618',
                                                                strokeWeight: 1,
                                                                scale: 8
                                                            }}
                                                            options={{
                                                                label: clickedMarkerIndex && clickedMarkerIndex.x.entorno.pt === item.entorno.pt ? {
                                                                    text: item.entorno.pt,
                                                                    fontSize: '14px',
                                                                    fontWeight: '600',
                                                                    className: 'text_marker'
                                                                } : null
                                                            }}
                                                        >
                                                        </Marker>
                                                    }

                                                    {item.entorno && !item.fotos &&
                                                        <Marker
                                                            position={{ lat: item.entorno.latitude, lng: item.entorno.longitude }}
                                                            onClick={() => handleMarkerClick(item)}
                                                            icon={{
                                                                path: window.google.maps.SymbolPath.CIRCLE,
                                                                fillColor: '#ef476f',
                                                                fillOpacity: 1,
                                                                strokeColor: '#fff',
                                                                strokeWeight: 1,
                                                                scale: 8
                                                            }}
                                                            options={{
                                                                label: clickedMarkerIndex && clickedMarkerIndex.x.entorno.pt === item.entorno.pt ? {
                                                                    text: item.entorno.pt,
                                                                    fontSize: '14px',
                                                                    fontWeight: '600',
                                                                    className: 'text_marker'
                                                                } : null
                                                            }}
                                                        >
                                                        </Marker>
                                                    }

                                                    {item.pedologia && !item.encerramento &&
                                                        <Marker
                                                            position={{ lat: item.entorno.latitude, lng: item.entorno.longitude }}
                                                            onClick={() => handleMarkerClick(item)}
                                                            icon={{
                                                                path: window.google.maps.SymbolPath.CIRCLE,
                                                                fillColor: '#ffd166',
                                                                fillOpacity: 1,
                                                                strokeColor: '#6e4c0d',
                                                                strokeWeight: 1,
                                                                scale: 8
                                                            }}
                                                            options={{
                                                                label: clickedMarkerIndex && clickedMarkerIndex.x.entorno.pt === item.entorno.pt ? {
                                                                    text: item.entorno.pt,
                                                                    fontSize: '14px',
                                                                    fontWeight: '600',
                                                                    className: 'text_marker'
                                                                } : null
                                                            }}
                                                        >
                                                        </Marker>
                                                    }

                                                    {item.fotos && !item.pedologia && !item.intransponibilidade &&
                                                        <Marker
                                                            position={{ lat: item.entorno.latitude, lng: item.entorno.longitude }}
                                                            onClick={() => handleMarkerClick(item)}
                                                            icon={{
                                                                path: window.google.maps.SymbolPath.CIRCLE,
                                                                fillColor: '#f8961e',
                                                                fillOpacity: 1,
                                                                strokeColor: '#220901',
                                                                strokeWeight: 1,
                                                                scale: 8
                                                            }}
                                                            options={{
                                                                label: clickedMarkerIndex && clickedMarkerIndex.x.entorno.pt === item.entorno.pt ? {
                                                                    text: item.entorno.pt,
                                                                    fontSize: '14px',
                                                                    fontWeight: '600',
                                                                    className: 'text_marker'
                                                                } : null
                                                            }}
                                                        >
                                                        </Marker>
                                                    }
                                                </div>
                                            )
                                        })}
                                </GoogleMap>
                            }
                        </div>
                    ) : <></>
                    }

                    {infoUser &&
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ width: "28%", borderRadius: 10, marginTop: 15, marginRight: 15 }}>

                                <div style={{
                                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5,
                                    padding: 3, paddingLeft: 10, paddingRight: 10, backgroundImage: 'linear-gradient(to right, #168aad, #52b69a)', borderRadius: 5
                                }}>
                                    <div>
                                        <Typography variant="subtitle2" sx={{ color: '#fff' }}>{infoUser.item[0]}</Typography>
                                        <Typography variant="caption" style={{ marginBottom: 10, color: '#fff' }}>
                                            Sincronizado em {syncTime[infoUser.item[0]]}
                                        </Typography>
                                    </div>

                                    <Typography variant="subtitle2" sx={{ color: '#fff' }}>{Object.values(infoUser.item[1]).length} /{Object.keys(task[infoUser.item[0]]).length}</Typography>
                                </div>


                                <div style={{ overflowY: 'scroll', height: '30vh', marginTop: 10, scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
                                    {infoUser.item[1] !== undefined ?
                                        <div>
                                            {Object.values(infoUser.item[1]).map((x, y) => {
                                                return (
                                                    <div key={y}>
                                                        {x.entorno &&
                                                            <div
                                                                style={{
                                                                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5,
                                                                    alignItems: 'center', marginBottom: 5, backgroundColor: '#fff', borderRadius: 7
                                                                }}>

                                                                <div style={{ display: 'flex', width: 80, alignContent: 'start' }}>
                                                                    <a style={{ fontSize: 14, fontWeight: 600 }}>{x.entorno.pt}</a>
                                                                </div>

                                                                {x.encerramento &&
                                                                    <div onClick={() => handleMarkerClick({ x: x, user: infoUser.item[0] })}
                                                                        style={{ backgroundColor: '#d8f3dc', padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 10, cursor: 'pointer' }}>
                                                                        <a style={{ color: '#2b9348', fontWeight: 500, fontSize: 13 }}>Concluído</a>
                                                                    </div>
                                                                }

                                                                {x.intransponibilidade &&
                                                                    <div onClick={() => handleMarkerClick({ x: x, user: infoUser.item[0] })}
                                                                        style={{ backgroundColor: '#d8f3dc', padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 10, cursor: 'pointer' }}>
                                                                        <a style={{ color: '#2b9348', fontWeight: 500, fontSize: 13 }}>Intransponível</a>
                                                                    </div>
                                                                }

                                                                {x.entorno && !x.fotos &&
                                                                    <div onClick={() => handleMarkerClick({ x: x, user: infoUser.item[0] })}
                                                                        style={{ backgroundColor: '#ffccd5', padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 10, cursor: 'pointer' }}>
                                                                        <a style={{ color: '#800f2f', fontWeight: 500, fontSize: 13 }}>Sem fotos</a>
                                                                    </div>
                                                                }

                                                                {x.pedologia && !x.encerramento &&
                                                                    <div onClick={() => handleMarkerClick({ x: x, user: infoUser.item[0] })}
                                                                        style={{ backgroundColor: '#ffccd5', padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 10, cursor: 'pointer' }}>
                                                                        <a style={{ color: '#800f2f', fontWeight: 500, fontSize: 13 }}>Não encerrado</a>
                                                                    </div>
                                                                }

                                                                {x.fotos && !x.pedologia && !x.intransponibilidade &&
                                                                    <div onClick={() => handleMarkerClick({ x: x, user: infoUser.item[0] })}
                                                                        style={{ backgroundColor: '#ffccd5', padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 10, cursor: 'pointer' }}>
                                                                        <a style={{ color: '#800f2f', fontWeight: 500, fontSize: 13 }}>Não executado</a>
                                                                    </div>
                                                                }

                                                                <div onClick={() => {
                                                                    setInfoDelete({ pt: x.entorno.pt, user: infoUser.item[0] });
                                                                    clickOpenDeletePT();
                                                                }}
                                                                    style={{ cursor: 'pointer', marginLeft: 8 }}>
                                                                    <RiDeleteBinLine style={{ fontSize: 20, color: '#6c757d', lineHeight: 0 }} />

                                                                </div>

                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}

                                            <Divider style={{ marginTop: 10, marginBottom: 5, fontWeight: 500 }}>Pontos sem cadastro</Divider>

                                            {infoUser.falta &&
                                                infoUser.falta.map((r, i) => {
                                                    return (
                                                        <div key={i} style={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5,
                                                            alignItems: 'center', marginBottom: 5, backgroundColor: '#fff', borderRadius: 7
                                                        }}>
                                                            <a style={{ fontSize: 14, fontWeight: 600 }}>{r}</a>

                                                            <div style={{
                                                                backgroundColor: '#e9ecef', padding: 4, paddingLeft: 8,
                                                                paddingRight: 8, borderRadius: 10
                                                            }}>
                                                                <a style={{ fontWeight: 500, fontSize: 13 }}>Pendente</a>
                                                            </div>

                                                        </div>
                                                    )
                                                })}
                                        </div>
                                        :
                                        <div>
                                            <a>Sem dados</a>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div style={{ width: "72%", display: 'flex', flexDirection: 'row' }}>

                                <div style={{
                                    width: "30%", display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                    alignItems: 'center', marginRight: 15, marginTop: 45
                                }}>

                                    <DemoPaper variant="outlined"
                                        style={{
                                            width: "100%", borderRadius: 5, display: 'flex', flexDirection: 'row',
                                            alignItems: 'center', backgroundColor: '#444054', marginBottom: 5,
                                        }}>
                                        <a style={{ fontWeight: 600, fontSize: 14, color: '#fff', marginLeft: 7 }}>Progresso</a>
                                    </DemoPaper>

                                    <DemoPaper variant="outlined"
                                        style={{
                                            width: "100%", borderRadius: 10, display: 'flex', flexDirection: 'row',
                                            justifyContent: 'center', alignItems: 'center', flex: 1
                                        }}>

                                        <PieChart width={300} height={200}>
                                            <Pie
                                                data={[
                                                    { name: "Concluídos", value: (Object.values(infoUser.item[1]).filter(item => item.encerramento).length) + (Object.values(infoUser.item[1]).filter(item => item.intransponibilidade).length) },
                                                    { name: "Pendentes", value: (Object.values(infoUser.item[1]).filter(item => item.entorno).length) - ((Object.values(infoUser.item[1]).filter(item => item.encerramento).length) + (Object.values(infoUser.item[1]).filter(item => item.intransponibilidade).length)) },
                                                ]}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={55}
                                                outerRadius={75}
                                                minAngle={5}
                                                stroke="none"
                                            >
                                                {[
                                                    { name: "Concluídos", value: (Object.values(infoUser.item[1]).filter(item => item.encerramento).length) + (Object.values(infoUser.item[1]).filter(item => item.intransponibilidade).length) },
                                                    { name: "Pendentes", value: (Object.values(infoUser.item[1]).filter(item => item.entorno).length) - ((Object.values(infoUser.item[1]).filter(item => item.encerramento).length) + (Object.values(infoUser.item[1]).filter(item => item.intransponibilidade).length)) },
                                                ].map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                            <Legend />
                                        </PieChart>

                                    </DemoPaper>

                                </div>

                                <div style={{
                                    width: "70%", display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                    alignItems: 'center', marginRight: 15, marginTop: 15, marginLeft: 10
                                }}>

                                    <div style={{
                                        width: "100%", borderRadius: 5, display: 'flex', flexDirection: 'row', marginTop: 5,
                                        marginBottom: 5, justifyContent: 'end'
                                    }}>
                                        <a onClick={() => {
                                            setInfoDataCampo(true)
                                            setInfoUser('')
                                        }} style={{ fontWeight: 600, fontSize: 14, cursor: 'pointer' }}>Fechar</a>
                                    </div>

                                    <DemoPaper
                                        variant="outlined"
                                        style={{
                                            width: "100%", borderRadius: 10, display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', alignItems: 'center', flex: 1,
                                            backgroundImage: 'linear-gradient(to right, #168aad, #52b69a)', padding: '10px'
                                        }}
                                    >
                                        <div style={{ padding: 5, borderRadius: 5, backgroundColor: '#fff', alignSelf: 'flex-start' }}>
                                            <a style={{ fontWeight: 600, fontSize: 14, color: '#168aad' }}>Intervenções por dia</a>
                                        </div>


                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>

                                            <LineChart width={550} height={150} data={agruparPorDia(Object.values(infoUser.item[1]))}>
                                                <Line type="monotone" dataKey="value" stroke="#fff" strokeWidth={2} />
                                                <XAxis dataKey="name" tick={{ fill: '#fff' }} stroke="#fff" />
                                                <Tooltip
                                                    contentStyle={{
                                                        backgroundColor: '#000000', // Cor de fundo do Tooltip
                                                    }} wrapperStyle={{
                                                        color: '#fff' // Cor do texto do Tooltip
                                                    }} />
                                            </LineChart>

                                            {/*
                                            {Object.values(infoUser.item[1]) &&
                                                agruparPorDia(Object.values(infoUser.item[1])).map((r, t) => {
                                                    return (
                                                        <div key={t}>
                                                            <a>{r}</a>
                                                        </div>
                                                    )
                                                })}
                                            */}
                                        </div>
                                    </DemoPaper>

                                </div>

                            </div>

                        </div>
                    }

                </div>

            </div>
        </div >
    )
}

export default InfoCampo;